<script setup lang="ts">
import type { DashboardSidebarLink } from '@nuxt/ui-pro/types'
import type { Group } from '#ui/types'

const route = useRoute()
const { push } = useRouter()
// const appConfig = useAppConfig()
/* const { isHelpSlideoverOpen } = useDashboard() */

const { hasNivelAcesso } = useAuthResolver()

const links = computed(() => {
	const allLinks = [{
		label: 'Dashboard',
		icon: 'i-streamline:money-graph-bar-product-data-bars-analysis-analytics-graph-business-chart',
		to: '/',
		tooltip: {
			text: 'Dashboard',
			shortcuts: ['G', 'H']
		}
	}, {
		label: 'Operacional',
		icon: 'i-heroicons-home',
		defaultOpen: route.path.startsWith('/operacional'),
		to: '/operacional/EsteiraProducao',
		tooltip: {
			text: 'Operacional',
			shortcuts: ['Shift', 'A']
		},
		children: [
			{
				label: 'Esteira de Produção',
				icon: '',
				to: '/operacional/EsteiraProducao',
				exact: true,
				tooltip: {
					text: 'Esteira de Produção',
					shortcuts: ['Shift', 'A']
				}
			},
			{
				label: 'Convênios',
				icon: '',
				to: '/operacional/Convenios',
				tooltip: {
					text: 'Convênios',
					shortcuts: ['Shift', 'C']
				}
			},
			{
				label: 'Roteiros Operacionais',
				icon: '',
				to: '/operacional/RoteirosOperacionais',
				tooltip: {
					text: 'Roteiros Operacionais',
					shortcuts: ['Shift', 'R']
				}
			},
			{
				label: 'Acessos',
				icon: '',
				to: '/operacional/Acessos',
				tooltip: {
					text: 'Acessos',
					shortcuts: ['Shift', 'X']
				}
			},
			{
				label: 'Bancos',
				icon: '',
				to: '/operacional/Bancos',
				tooltip: {
					text: 'Bancos',
					shortcuts: ['Shift', 'B']
				}
			},
			{
				label: 'Produtos',
				icon: '',
				to: '/operacional/Produtos',
				tooltip: {
					text: 'Produtos',
					shortcuts: ['Shift', 'T']
				}
			},
			{
				label: 'Promotoras',
				icon: '',
				to: '/operacional/Promotoras'
			},
			{
				label: 'Operações',
				icon: '',
				to: '/operacional/Operacoes'
			},
			{
				label: 'Origens de Vendas',
				icon: '',
				to: '/operacional/OrigensVendas'
			}
		]
	},
	{
		label: 'RH / DP',
		icon: 'i-streamline:interface-user-check-actions-close-checkmark-check-geometric-human-person-single-success-up-user',
		defaultOpen: route.path.startsWith('/rh'),
		to: '/rh/AvaliacoesDesempenho',
		children: [
			{
				label: 'Avaliações de Desempenho',
				icon: '',
				to: '/rh/AvaliacoesDesempenho'
			},
			{
				label: 'Questões de Avaliação',
				icon: '',
				to: '/rh/QuestoesAvaliacao'
			},
			{
				label: 'Calendário Administrativo',
				icon: '',
				to: '/rh/CalendarioAdministrativo'
			},
			{
				label: 'Colaboradores',
				icon: '',
				to: '/rh/Colaboradores'
			},
			{
				label: 'Comunicados',
				icon: '',
				to: '/rh/Comunicados'
			},
			{
				label: 'CRM',
				icon: '',
				to: '/rh/Crm'
			},
			{
				label: 'Equipes',
				icon: '',
				to: '/rh/Equipes'
			}
		]
	},
	{
		label: 'Financeiro',
		icon: 'i-material-symbols:attach-money',
		defaultOpen: route.path.startsWith('/financeiro'),
		to: '/financeiro/CalendarioFinanceiro',
		children: [
			{
				label: 'Calendário Financeiro',
				icon: '',
				to: '/financeiro/CalendarioFinanceiro',
				exact: true
			},
			{
				label: 'Despesas Variáveis',
				icon: '',
				to: '/financeiro/DespesasVariaveis',
				exact: true
			},
			{
				label: 'Grupo comissões',
				icon: '',
				to: '/financeiro/GrupoComissoes',
				exact: true
			},
			{
				label: 'Metas',
				icon: '',
				to: '/financeiro/Metas',
				exact: false
			},
			{
				label: 'Pagamentos',
				icon: '',
				to: '/financeiro/Pagamentos',
				exact: false
			},
			{
				label: 'Resumo Financeiro',
				icon: '',
				to: '/financeiro/ResumoFinanceiro',
				exact: true
			},
			{
				label: 'Cadastros',
				icon: '',
				to: '/financeiro/cadastro',
				exact: false
			}
		]
	}, {
		label: 'Afiliados',
		disabled: true,
		icon: 'i-pajamas:partner-verified',
		defaultOpen: route.path.startsWith('/financeiro'),
		to: '/afiliados/VisaoGeral',
		children: [
			{
				label: 'Visão geral',
				icon: '',
				to: '/afiliados/VisaoGeral',
				exact: true
			},
			{
				label: 'Esteira de produção',
				icon: '',
				to: '/afiliados/EsteiraProducao',
				exact: true
			},
			{
				label: 'Pagamentos',
				icon: '',
				to: '/afiliados/Pagamentos',
				exact: true
			},
			{
				label: 'Resumo Financeiro',
				icon: '',
				to: '/afiliados/ResumoFinanceiro',
				exact: true
			}
		]
	}] as DashboardSidebarLink[]

	return allLinks.filter((link) => {
		if (link.label === 'Dashboard') return true
		if (link.label === 'Operacional') return hasNivelAcesso(ROLES.Diretoria, ROLES.Operacional, ROLES.Administrativo)
		if (link.label === 'RH / DP') return hasNivelAcesso(ROLES.Diretoria, ROLES.Administrativo, ROLES.Financeiro)
		if (link.label === 'Financeiro') return hasNivelAcesso(ROLES.Diretoria, ROLES.Administrativo, ROLES.Financeiro)
		if (link.label === 'Afiliados') return hasNivelAcesso(ROLES.Diretoria)
		return false
	})
})

const footerLinks: DashboardSidebarLink[] = [
	/* {
    label: 'Invite people',
    icon: 'i-heroicons-plus',
    to: '/settings/members'
  }, {
    label: 'Help & Support',
    icon: 'i-heroicons-question-mark-circle',
    click: () => isHelpSlideoverOpen.value = true
  } */
]

const groups: Group[] = [
	{
		key: 'actions',
		label: 'Ações Rápidas',
		commands: [{
			label: 'Cadastrar Proposta',
			icon: 'i-heroicons-plus',
			to: '/CadastrarProposta',
			shortcuts: ['Shift', 'P'],
			id: 'cadastrar-proposta'
		}]
	},
	{
		key: 'operacional',
		label: 'Operacional',
		commands: [
			{
				label: 'Esteira de Produção',
				icon: 'i-heroicons-cog',
				to: '/operacional/EsteiraProducao',
				id: 'nav-operacional-esteira-producao'
			},
			{
				label: 'Convênios',
				icon: 'i-heroicons-building-office',
				to: '/operacional/Convenios',
				id: 'nav-operacional-convenios'
			},
			{
				label: 'Roteiros Operacionais',
				icon: 'i-heroicons-clipboard-document-list',
				to: '/operacional/RoteirosOperacionais',
				id: 'nav-operacional-roteiros-operacionais'
			},
			{
				label: 'Acessos',
				icon: 'i-heroicons-key',
				to: '/operacional/Acessos',
				id: 'nav-operacional-acessos'
			},
			{
				label: 'Bancos',
				icon: 'i-heroicons-building-library',
				to: '/operacional/Bancos',
				id: 'nav-operacional-bancos'
			},
			{
				label: 'Operações',
				icon: 'i-heroicons-cog-6-tooth',
				to: '/operacional/Operacoes',
				id: 'nav-operacional-operacoes'
			},
			{
				label: 'Produtos',
				icon: 'i-heroicons-cube',
				to: '/operacional/Produtos',
				id: 'nav-operacional-produtos'
			},
			{
				label: 'Promotoras',
				icon: 'i-heroicons-building-storefront',
				to: '/operacional/Promotoras',
				id: 'nav-operacional-promotoras'
			}
		]
	},
	{
		key: 'rh',
		label: 'RH / DP',
		commands: [
			{
				label: 'Colaboradores',
				icon: 'i-heroicons-users',
				to: '/rh/Colaboradores',
				id: 'nav-rh-colaboradores'
			},
			{
				label: 'Equipes',
				icon: 'i-heroicons-user-group',
				to: '/rh/Equipes',
				id: 'nav-rh-equipes'
			},
			{
				label: 'Cargos',
				icon: 'i-heroicons-briefcase',
				to: '/rh/Cargos',
				id: 'nav-rh-cargos'
			},
			{
				label: 'Avaliações de Desempenho',
				icon: 'i-heroicons-chart-bar',
				to: '/rh/AvaliacoesDesempenho',
				id: 'nav-rh-avaliacoes-desempenho'
			},
			{
				label: 'Folha de Pagamento',
				icon: 'i-heroicons-currency-dollar',
				to: '/rh/FolhaPagamento',
				id: 'nav-rh-folha-pagamento'
			},
			{
				label: 'Férias',
				icon: 'i-heroicons-calendar',
				to: '/rh/Ferias',
				id: 'nav-rh-ferias'
			},
			{
				label: 'Benefícios',
				icon: 'i-heroicons-gift',
				to: '/rh/Beneficios',
				id: 'nav-rh-beneficios'
			},
			{
				label: 'Treinamentos',
				icon: 'i-heroicons-academic-cap',
				to: '/rh/Treinamentos',
				id: 'nav-rh-treinamentos'
			}
		]
	},
	{
		key: 'financeiro',
		label: 'Financeiro',
		icon: 'i-material-symbols:attach-money',
		defaultOpen: route.path.startsWith('/financeiro'),
		to: '/financeiro/CalendarioFinanceiro',
		commands: [
			{
				label: 'Calendário Financeiro',
				icon: 'i-heroicons-calendar',
				to: '/financeiro/CalendarioFinanceiro',
				id: 'nav-financeiro-calendario'
			},
			{
				label: 'Despesas Variáveis',
				icon: 'i-heroicons-banknotes',
				to: '/financeiro/DespesasVariaveis',
				id: 'nav-financeiro-despesas'
			},
			{
				label: 'Grupo comissões',
				icon: 'i-heroicons-user-group',
				to: '/financeiro/GrupoComissoes',
				id: 'nav-financeiro-comissoes'
			},
			{
				label: 'Metas',
				icon: 'i-heroicons-flag',
				to: '/financeiro/Metas',
				id: 'nav-financeiro-metas'
			},
			{
				label: 'Pagamentos',
				icon: 'i-heroicons-credit-card',
				to: '/financeiro/Pagamentos',
				id: 'nav-financeiro-pagamentos'
			},
			{
				label: 'Resumo Financeiro',
				icon: 'i-heroicons-document-chart-bar',
				to: '/financeiro/ResumoFinanceiro',
				id: 'nav-financeiro-resumo'
			}
		]
	}
]

// const defaultColors = ref(['green', 'teal', 'cyan', 'sky', 'blue', 'indigo', 'violet'].map(color => ({ label: color, chip: color, click: () => appConfig.ui.primary = color })))
// const colors = computed(() => defaultColors.value.map(color => ({ ...color, active: appConfig.ui.primary === color.label })))
const isDark = computed(() => useColorMode().value == 'dark')
const bgColor = computed(() => (isDark.value ? '#000' : '#fff'))
</script>

<template>
	<UDashboardLayout>
		<UDashboardPanel
			class="select-none"
			:width="250"
			:resizable="{ min: 200, max: 300 }"
			collapsible
		>
			<UDashboardNavbar
				class="!border-transparent"
				:ui="{ left: 'flex-1' }"
			>
				<template #left>
					<TeamsDropdown />
				</template>
			</UDashboardNavbar>

			<UDashboardSidebar>
				<template #header>
					<UButton
						class="rounded-3xl mb-2"
						leading-icon="i-heroicons-plus"
						@click="() => push('/CadastrarProposta')"
					>
						Cadastrar proposta
					</UButton>
					<!-- <GradientButton
						class="mb-2"
						:bg-color="bgColor"
						@click="() => push('/CadastrarProposta')"
					>
						Cadastrar proposta
					</GradientButton> -->
					<UDashboardSearchButton label="Procurar..." />
				</template>

				<UDashboardSidebarLinks :links="links" />

				<UDivider />

				<!--        <UDashboardSidebarLinks
          :links="[{ label: 'Colors', draggable: true, children: colors }]"
          @update:links="colors => defaultColors = colors"
        /> -->

				<div class="flex-1" />

				<!-- <UDashboardSidebarLinks :links="footerLinks" /> -->
				<template #footer>
					<UserDropdown />
				</template>
			</UDashboardSidebar>
		</UDashboardPanel>

		<slot />

		<!-- ~/components/HelpSlideover.vue -->
		<HelpSlideover />
		<!-- ~/components/NotificationsSlideover.vue -->
		<NotificationsSlideover />

		<ClientOnly>
			<LazyUDashboardSearch :groups="groups" />
		</ClientOnly>
	</UDashboardLayout>
</template>
